import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { navigate } from 'gatsby'

import Layout from '../../templates/Layout'
import * as S from '../../styles/login.styles'
import * as I from '../../components/shared/Icons'
import { PRIMARY, SECONDARY, BACKGROUND } from '../../constants/colors'
import * as session from '../../services/session'
import { HOME_ROUTE, LOGIN_ROUTE, WORKOUTS_ROUTE } from '../../constants/routes'
import { ErrorMessage, InfoMessage } from '../../components/shared'
import { forgotPasswordInitiate } from '../../services/fetch/user'
import { defaultErrorMsg } from '../../components/forms/util/helpers'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withErrorHandler } from '../../components/errorHandler'

const ForgotPassword: React.FC = () => {
  session.redirectIfLoggedIn(WORKOUTS_ROUTE)
  const [email, setEmail] = useState<string>('')
  const [pending, setPending] = useState<boolean>(false)
  const [done, setDone] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const validateEmail = (value: string): boolean => {
    const regex = /\S+@\S+\.\S+/
    return !regex.test(value)
  }

  const { color, opacity } = useSpring({
    color: validateEmail(email) ? BACKGROUND : SECONDARY,
    opacity: validateEmail(email) ? 0.2 : 1,
  })

  const { color: textColor } = useSpring({
    color: validateEmail(email) ? SECONDARY : BACKGROUND,
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPending(true)
    setError('')

    forgotPasswordInitiate({ email })
      .then(() => {
        // successfull
        setPending(false)
        setDone(true)
      })
      .catch(err => {
        setEmail('')
        setPending(false)
        setError((err && err?.message) || defaultErrorMsg)
      })
  }

  return (
    <Layout>
      <S.Container>
        {done ? (
          <>
            <InfoMessage
              message="Your request has been submitted! Please check your email for a link to
      reset your password."
            />
            <S.SecondaryText
              onClick={() => navigate(HOME_ROUTE)}
              style={{
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              Back To Home
            </S.SecondaryText>
          </>
        ) : (
          <>
            <S.HeaderIconContainer>
              <I.Lock color={PRIMARY} strokeWidth={1.5} />
            </S.HeaderIconContainer>
            <S.SecondaryText
              style={{ fontSize: 22, textAlign: 'center', marginBottom: 16 }}
            >
              Trouble Logging In?
            </S.SecondaryText>
            <S.SecondaryText
              style={{ fontSize: 16, textAlign: 'center', marginBottom: 12 }}
            >
              Enter your email and we&apos;ll send you a link to get back into
              your account.
            </S.SecondaryText>
            <ErrorMessage message={error} style={{ width: 350 }} />
            <S.FormWrap onSubmit={handleSubmit}>
              <S.FormInputDiv>
                <S.FormInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={e => setEmail(e.target.value)}
                />
              </S.FormInputDiv>
              <S.SubmitButton
                as={animated.button}
                style={{
                  backgroundColor: color,
                }}
                type="submit"
                disabled={validateEmail(email)}
              >
                <S.SubmitText
                  as={animated.div}
                  style={{ color: textColor, opacity }}
                >
                  {pending ? 'Sending Login Link...' : 'Send Login Link'}
                </S.SubmitText>
                {pending && (
                  <S.SpinnerWrap>
                    <CircularProgress size={18} color="secondary" />
                  </S.SpinnerWrap>
                )}
              </S.SubmitButton>
            </S.FormWrap>
            <S.SecondaryText
              onClick={() => navigate(LOGIN_ROUTE)}
              style={{
                color: SECONDARY,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              Back To Login
            </S.SecondaryText>
          </>
        )}
      </S.Container>
    </Layout>
  )
}

export default withErrorHandler(ForgotPassword)
